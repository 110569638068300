import * as React from 'react';
import { memo } from 'react';
import { useStyles } from './styles';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store/reducers';
import { clearSnackbar } from 'store/Snackbar/snackbar.actions';
import { getSnackbarState } from '../../../store/Snackbar/snackbar.selectors';

const GlobalSnackbar = memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { open, text, severity } = useSelector((state: AppState) => getSnackbarState(state));

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{
        root: classes.snackbar,
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert variant="filled" onClose={handleClose} severity={severity} classes={{ root: classes.root, icon: classes.icon }}>
        {text}
      </Alert>
    </Snackbar>
  );
});

export default GlobalSnackbar;
