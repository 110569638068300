import actionCreatorFactory from 'typescript-fsa';
import { Stock } from './stocks.types';

const actionCreator = actionCreatorFactory('STOCKS');

export const init = actionCreator('INIT');

export const stocks = actionCreator<string>('STOCKS', { retry: true });
export const stocksAsync = actionCreator.async<null, string>('STOCKS_ASYNC');

export const setStocksData = actionCreator<{ indicator: string; comparison: Stock[], timestamp: number }>('SET_STOCKS_DATA');
