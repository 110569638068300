import { call, put, takeLatest } from 'redux-saga/effects';
import { callApi } from 'api/callApi';
import { bindAsyncAction } from 'helpers/saga.utils';
import { setStocksData, stocks, stocksAsync } from './stocks.actions';

function* watchForStocks() {
  yield takeLatest(
    stocks,
    bindAsyncAction(stocksAsync)(function* (action: ReturnType<typeof stocks>) {
      const { payload: indicator } = action;
      try {
        const now = new Date();
        const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours());
        const timestamp = startOfDay.getTime() / 1000;
        //  new Date().getTime()
        const body = {
          indicator,
          timestamp,
        };
        const response = yield call(callApi, {
          url: `data.php`,
          method: 'GET',
          body,
        });
        if (response !== null && Array.isArray(response.comparison)) {
          yield put(setStocksData({ indicator, ...response }));
        }
      } catch (e) {
        console.log(e);
      }
    }),
  );
}

export default [watchForStocks];
