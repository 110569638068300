import { call, put, takeLatest } from 'redux-saga/effects';
import { callApi } from 'api/callApi';
import { bindAsyncAction } from 'helpers/saga.utils';
import { quote, quoteAsync, setQuoteData } from './quotes.actions';

function* watchForQuote() {
  yield takeLatest(
    quote,
    bindAsyncAction(quoteAsync)(function* (action: ReturnType<typeof quote>) {
      const { payload: symbol } = action;
      try {
        // const now = new Date();
        // const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours());
        // const timestamp = startOfDay.getTime() / 1000;
         const timestamp = new Date().getTime()
        const body = {
          symbol,
          timestamp,
        };
        const response = yield call(callApi, {
          url: `stock.php`,
          method: 'GET',
          body,
        });
        if (response !== null) {
          yield put(setQuoteData({ symbol, ...response }));
        }
      } catch (e) {
        console.log(e);
      }
    }),
  );
}

export default [watchForQuote];
