import actionCreatorFactory from 'typescript-fsa';
import { Quote } from './quotes.types';

const actionCreator = actionCreatorFactory('QUOTES');

export const init = actionCreator('INIT');

export const quote = actionCreator<string>('QUOTE', { retry: true });
export const quoteAsync = actionCreator.async<null, string>('QUOTE_ASYNC');

export const setQuoteData = actionCreator<{ symbol: string; quote: Quote }>('SET_QUOTE_DATA');
