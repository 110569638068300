import { Action, combineReducers } from 'redux';
import { StocksState } from './Stocks/stocks.types';
import { QuotesState } from './Quotes/quotes.types';
import { ContactState } from './Contact/contact.types';
import { SnackbarState } from './Snackbar/snackbar.types';
import stocks from './Stocks/stocks.reducer';
import quotes from './Quotes/quotes.reducer';
import contact from './Contact/contact.reducer';
import snackbar from './Snackbar/snackbar.reducer';

export interface AppState {
  stocks: StocksState;
  quotes: QuotesState;
  contact: ContactState;
  snackbar: SnackbarState;
}

const appReducer = combineReducers<AppState>({
  stocks,
  quotes,
  contact,
  snackbar,
} as any);

export const rootReducer = (state: AppState | undefined, action: Action) => {
  // if (action.url === PURGE) {
  //   state = undefined;
  // }

  return appReducer(state, action);
};
