import actionCreatorFactory from 'typescript-fsa';
import { Contact } from './contact.types';

const actionCreator = actionCreatorFactory('CONTACT');

export const init = actionCreator('INIT');

export const contact = actionCreator<Contact>('CONTACT', { retry: true });
export const contactAsync = actionCreator.async<null, Contact>('CONTACT_ASYNC');

export const setSuccess = actionCreator<{ success: boolean }>('SET_SUCCESS');
