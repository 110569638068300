import { Action, isType } from 'typescript-fsa';
import { clearSnackbar, init, setSnackbar } from './snackbar.actions';
import { SnackbarState } from './snackbar.types';

export const initialState: SnackbarState = {
  open: false,
  severity: 'success',
  text: '',
};

export default function reduce(state: SnackbarState = initialState, action: Action<any>) {
  if (false) {
    console.log(action.type);
    console.log(action.payload);
  }

  if (isType(action, init)) {
    return initialState;
  }

  if (isType(action, clearSnackbar)) {
    return {
      ...state,
      open: false,
    };
  }

  if (isType(action, setSnackbar)) {
    const { payload } = action;
    return {
      ...state,
      ...payload,
    };
  }

  return state;
}
