import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

export interface IAppContainerStyles {
  maxWidth: React.CSSProperties['width'];
  width: React.CSSProperties['width'];
  marginLeft: React.CSSProperties['marginLeft'];
  marginRight: React.CSSProperties['marginRight'];
  position: React.CSSProperties['position'];
  paddingLeft: React.CSSProperties['paddingLeft'];
  paddingRight: React.CSSProperties['paddingRight'];
}

const breakpoints = createBreakpoints({});

export const appContainerStyles: IAppContainerStyles = {
  maxWidth: 1024,
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'relative',
  paddingLeft: '0.625em',
  paddingRight: '0.625em',
  [breakpoints.up('md')]: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
};
