import { Typography, TypographyProps } from '@material-ui/core';
import React from 'react';
import { Skeleton } from '@material-ui/lab';

const variants = ['h1', 'h1', 'h2', 'h3', 'h1', 'h1', 'h2', 'h3', 'h1', 'h2', 'h3'] as TypographyProps['variant'][];

interface Props {
  white?: boolean;
}

const Preloader = ({ white }: Props) => {
  if (white) {
    return <div />;
  } else {
    return (
      <div>
        {variants.map((variant, index) => (
          <Typography component="div" key={index.toString()} variant={variant}>
            <Skeleton />
          </Typography>
        ))}
      </div>
    );
  }
};

export default Preloader;
