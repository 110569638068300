import { call, delay, race } from 'redux-saga/effects';
import { Api, CallApiOptions } from './api';
import { TimeoutError } from './errors';
import { HOST_URL } from 'config/config';

export const apiCaller = new Api({
  apiUrl: HOST_URL,
  headers: {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/json',
  },
});

const TIMEOUT_DELAY = 30000;

const callServiceWorker = function* (options: CallApiOptions) {
  try {
    /** if a 401 comes from a login, then we use normal handling, if 401 comes from any other call, we log out the user.
     * 401 can mean either an access-token expiration or a deletion of this user. Both should cause a logout.
     */
    const { error, data } = yield call(apiCaller.call, options);

    if (error) {
      if (error.status === 401 && options.url !== 'access-tokens') {
        // yield put(logout());
        // showWarning(I18n.t('ErrorLogout'));
        throw error;
      }
      if (options.showError === undefined || options.showError === true) {
        // showWarning(error.message);
      } else {
        console.warn(`Silent error: ${error.message}`);
      }
    } else {
      // hideToast();
    }

    if (error) {
      throw error;
    }

    return data;
  } catch (e) {
    if (e instanceof TypeError && e.message.indexOf('Network request failed') > -1) {
      throw new TimeoutError(`Network request failed`);
    }

    throw e;
  }
};

export function* callApi(options: CallApiOptions) {
  const timeoutDelay = options.timeout || TIMEOUT_DELAY;
  const { result, timeout } = yield race({
    result: call(callServiceWorker, options),
    timeout: delay(timeoutDelay),
  });

  if (timeout) {
    throw new TimeoutError(`${timeoutDelay} passed`);
  }

  return result;
}
