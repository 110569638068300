import { Action, isType } from 'typescript-fsa';
import { contactAsync, init, setSuccess } from './contact.actions';
import { ASYNC_STATUS } from 'helpers/saga.utils';
import { ContactState } from './contact.types';

export const initialState: ContactState = {
  asyncStatus: ASYNC_STATUS.DONE,
  success: false,
};

export default function reduce(state: ContactState = initialState, action: Action<any>) {
  if (false) {
    console.log(action.type);
    console.log(action.payload);
  }

  if (isType(action, init)) {
    return initialState;
  }

  if (isType(action, contactAsync.started)) {
    return { ...state, asyncStatus: ASYNC_STATUS.LOADING };
  }

  if (isType(action, contactAsync.done)) {
    return { ...state, asyncStatus: ASYNC_STATUS.DONE };
  }

  if (isType(action, contactAsync.failed)) {
    return { ...state, asyncStatus: ASYNC_STATUS.ERROR };
  }

  if (isType(action, setSuccess)) {
    const {
      payload: { success },
    } = action;
    return { ...state, success };
  }

  return state;
}
