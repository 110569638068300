import { call, put, cancelled } from 'redux-saga/effects';
import { AsyncActionCreators } from 'typescript-fsa';

export enum ASYNC_STATUS {
  LOADING,
  DONE,
  ERROR,
}

export interface AsyncItemsContainer {
  asyncStatus: ASYNC_STATUS;
}

export function bindAsyncAction(actionCreator: AsyncActionCreators<any, any, any>) {
  return (worker: (...args: any[]) => void) => {
    const bound = function*(params: any = null) {
      yield put(actionCreator.started(params));
      try {
        const result = yield call(worker, params);
        yield put(actionCreator.done({ params, result }));

        return result;
      } catch (error) {
        yield put(actionCreator.failed({ params, error }));
        throw error;
      } finally {
        if (yield cancelled()) {
          yield put(actionCreator.failed({ params, error: 'cancelled' }));
        }
      }
    };

    return bound;
  };
}
