import { Action, isType } from 'typescript-fsa';
import { init, setStocksData, stocksAsync } from './stocks.actions';
import { StocksState } from './stocks.types';
import { ASYNC_STATUS } from 'helpers/saga.utils';

export const initialState: StocksState = {
  asyncStatus: ASYNC_STATUS.DONE,
  comparison: {},
  timestamp: 0,
};

export default function reduce(state: StocksState = initialState, action: Action<any>) {
  if (false) {
    console.log(action.type);
    console.log(action.payload);
  }

  if (isType(action, init)) {
    return initialState;
  }

  if (isType(action, stocksAsync.started)) {
    return { ...state, asyncStatus: ASYNC_STATUS.LOADING };
  }

  if (isType(action, stocksAsync.done)) {
    return { ...state, asyncStatus: ASYNC_STATUS.DONE };
  }

  if (isType(action, stocksAsync.failed)) {
    return { ...state, asyncStatus: ASYNC_STATUS.ERROR };
  }

  if (isType(action, setStocksData)) {
    const { payload } = action;
    return {
      ...state,
      comparison: {
        ...state.comparison,
        [payload.indicator]: payload.comparison,
      },
      timestamp: payload.timestamp,
    };
  }

  return state;
}
