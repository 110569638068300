import { Theme, ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { appContainerStyles } from './properties/new/appContainerStyles';
import { common, cyan, grey, lime, pink } from '@material-ui/core/colors';
// https://material-ui.com/customization/theming/ this helps with the Collapse and https://stackoverflow.com/a/64135466
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

export function getMuiTheme(options: ThemeOptions): Theme {
  return createMuiTheme({
    ...options,
  });
}

const breakpoints = createBreakpoints({});

export const muiTheme: Theme = getMuiTheme({
  appContainerStyles,
  palette: {
    primary: { main: cyan[200], contrastText: common.black },
    secondary: { main: lime[800], contrastText: common.white },
    error: { main: pink[600], contrastText: common.white },
    text: {
      primary: common.black,
      secondary: cyan[200],
    },
  },
  typography: {
    fontFamily: ['"Helvetica Neue"', 'Roboto', '-apple-system', 'Arial', 'sans-serif'].join(','),
    subtitle1: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.5,
      color: grey[900],
      [breakpoints.up('md')]: {
        fontSize: 14,
      },
    },
    subtitle2: {
      fontSize: 10,
      fontWeight: 800,
      lineHeight: 1.5,
      color: grey[900],
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.5,
      color: grey[900],
    },
    h1: {
      fontSize: 24,
      lineHeight: 1.5,
      fontWeight: 800,
      color: cyan[900],
      [breakpoints.up('md')]: {
        fontSize: 32,
        lineHeight: 1.5,
      },
    },
    h2: {
      fontSize: 18,
      lineHeight: 1.5,
      fontWeight: 800,
      color: grey[600],
      [breakpoints.up('md')]: {
        fontSize: 22,
      },
    },
    h6: {
      fontSize: 14,
      lineHeight: 1.3,
      fontWeight: 'bold',
      color: grey[900],
      [breakpoints.up('md')]: {
        fontSize: 16,
      },
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: common.white,
        },
      },
    },
    MuiTabs: {
      root: {
        minHeight: 32,
        [breakpoints.up('md')]: {
          minHeight: 48,
        },
      },
    },
    MuiTab: {
      root: {
        fontSize: '0.75rem',
        minHeight: 34,
        [breakpoints.up('md')]: {
          fontSize: '0.875rem',
          minHeight: 48,
        },
        '&:hover': {
          backgroundColor: cyan[100],
        },
        // '&.Mui-selected': {
        //   backgroundColor: cyan[300],
        // },
      },
    },
    MuiRadio: {
      root: {
        // icon squeeze, hack
        // padding: '4px 12px 0 12px',
        marginTop: -5,
        marginBottom: -9,
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: grey[600],
        },
        fontSize: 18,
        lineHeight: 1.5,
        fontWeight: 800,
        color: grey[600],
        [breakpoints.up('md')]: {
          fontSize: 22,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 16,
        lineHeight: 1.5,
        color: grey[900],
      },
    },
    MuiFormHelperText: {
      root: {
        // marginTop: -6,
        // paddingLeft: 31,
        color: grey[600],
      },
    },
    MuiPaper: {
      elevation1: {
        // we use the elevation3 shadow, because elevation 1 is the default
        boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
      },
      rounded: {
        borderRadius: '4px 10px 4px 10px',
      },
      root: {
        backgroundColor: lime[50],
      },
    },
    // MuiButtonBase: {
    //   root: {
    //     '&:focus,&:hover,&:visited,&:link,&:active': {
    //       textDecoration: 'none',
    //       color: 'black',
    //     },
    //   },
    // },
    MuiTablePagination: {
      root: {
        backgroundColor: cyan[50],
      },
      selectRoot: {
        marginRight: 8,
        marginLeft: 0,
        [breakpoints.up('sm')]: {
          marginRight: 32,
          marginLeft: 8,
        },
      },
      actions: {
        marginLeft: 0,
        [breakpoints.up('sm')]: {
          marginLeft: 20,
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: 8,
        backgroundColor: common.white,
      },
    },
    MuiDialogActions: {
      root: {
        root: {
          margin: 0,
          padding: 4,
        },
      },
    },
  },
});
