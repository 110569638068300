import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware, { END } from 'redux-saga';
import { rootReducer } from './reducers';
import { rootSaga } from './sagas';
import { createLogger } from 'redux-logger';
import { __DEV__ } from '../helpers/platform';

const sagaMiddleware = createSagaMiddleware({
  onError: (e) => {
    console.warn(`Saga Error Middleware: ${e}`);
  },
});

// const migrations = {
//   1: (state: AppState) => {
//     return {
//       ...state,
//       app: {
//         ...state.app,
//         baseUrl: state.app.baseUrl || GLUE_URL_PREPROD,
//         hostUrl: state.app.hostUrl || HOST_URL_PREPROD,
//       },
//     };
//   },
// };

function configureStore(initialState = {}) {
  // const persistConfig: PersistConfig<any> = {
  //   key: 'root',
  //   version: 0,
  //   storage,
  //   // migrate: createMigrate(migrations as any),
  // };

  // const reducers = persistReducer(persistConfig, rootReducer);

  // https://medium.com/@zalmoxis/improve-your-development-workflow-with-redux-devtools-extension-f0379227ff83
  const windowIfDefined = typeof window === 'undefined' ? null : (window as any);
  const composeEnhancers = windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const logger = createLogger({
    predicate: () => __DEV__,
  });

  let middleware = [sagaMiddleware /*routerMiddleware(history),*/, logger];

  const store = createStore(rootReducer, initialState as any, composeEnhancers(applyMiddleware(...middleware)));

  sagaMiddleware.run(rootSaga);

  return {
    store: {
      ...store,
      runSaga: sagaMiddleware.run,
      close: () => {
        store.dispatch(END);
      },
    },
  };
}

const store = configureStore().store;
// const persistor = persistStore(store);

export { store /*, persistor */ };
