import { Action, isType } from 'typescript-fsa';
import { init, quoteAsync, setQuoteData } from './quotes.actions';
import { QuotesState } from './quotes.types';
import { ASYNC_STATUS } from 'helpers/saga.utils';

export const initialState: QuotesState = {
  asyncStatus: ASYNC_STATUS.DONE,
  quotes: {},
};

export default function reduce(state: QuotesState = initialState, action: Action<any>) {
  if (false) {
    console.log(action.type);
    console.log(action.payload);
  }

  if (isType(action, init)) {
    return initialState;
  }

  if (isType(action, quoteAsync.started)) {
    return { ...state, asyncStatus: ASYNC_STATUS.LOADING };
  }

  if (isType(action, quoteAsync.done)) {
    return { ...state, asyncStatus: ASYNC_STATUS.DONE };
  }

  if (isType(action, quoteAsync.failed)) {
    return { ...state, asyncStatus: ASYNC_STATUS.ERROR };
  }

  if (isType(action, setQuoteData)) {
    const { payload } = action;
    return {
      ...state,
      quotes: {
        ...state.quotes,
        [payload.symbol]: payload,
      },
    };
  }

  return state;
}
