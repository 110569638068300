import { call, put, takeLatest } from 'redux-saga/effects';
import { callApi } from 'api/callApi';
import { bindAsyncAction } from 'helpers/saga.utils';
import { contact, contactAsync, setSuccess } from './contact.actions';
import { setSnackbar } from '../Snackbar/snackbar.actions';

function* watchForContact() {
  yield takeLatest(
    contact,
    bindAsyncAction(contactAsync)(function* (action: ReturnType<typeof contact>) {
      const { payload: data } = action;
      try {
        const body = data;
        const response = yield call(callApi, {
          url: `contact.php`,
          method: 'POST',
          body,
        });
        if (response?.status === 'Error') {
          yield put(
            setSnackbar({ open: true, text: 'Die Nachricht konnte nicht versendet werden', severity: 'error' }),
          );
          throw new Error('Die Nachricht konnte nicht versendet werden');
        } else {
          yield put(setSuccess({ success: true }));
        }
      } catch (e) {
        console.log(e);
      }
    }),
  );
}

export default [watchForContact];
