import { all, call, spawn } from 'redux-saga/effects';
import stocksSagas from './Stocks/stocks.saga';
import quotesSaga from './Quotes/quotes.saga';
import contactSaga from './Contact/contact.saga';

const SAGAS = [...stocksSagas, ...quotesSaga, ...contactSaga];

export function* rootSaga(): any {
  yield all([
    ...SAGAS.map((saga) =>
      spawn(function* () {
        try {
          yield call(saga);
        } catch (e) {
          console.warn(`Unwinded: ${e}`);
        }
      }),
    ),
  ]);
}
