import React, { Suspense } from 'react';
import { AppBar, Tab, Tabs, useTheme } from '@material-ui/core';
import { Link, Route, RouteComponentProps, Switch } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import { useStyles } from './styles';
import { common } from '@material-ui/core/colors';
import { initGA } from 'helpers/analytics';
import Preloader from 'application/components/Preloader/Preloader';
import GlobalSnackbar from 'application/components/GlobalSnackbar/GlobalSnackbar';

const HomePage = React.lazy(() => import('application/pages/HomePage/HomePage'));
const ImprintPage = React.lazy(() => import('application/pages/ImprintPage/ImprintPage'));
const NotFoundPage = React.lazy(() => import('application/pages/NotFoundPage/NotFoundPage'));
const StocksPage = React.lazy(() => import('application/pages/StocksPage/StocksPage'));
const InstagramPage = React.lazy(() => import('application/pages/InstagramPage/InstagramPage'));
const QuotePage = React.lazy(() => import('application/pages/QuotePage/QuotePage'));
const FaqPage = React.lazy(() => import('application/pages/FaqPage/FaqPage'));
const ContactPage = React.lazy(() => import('application/pages/ContactPage/ContactPage'));
const ContactSuccessPage = React.lazy(() => import('application/pages/ContactSuccessPage/ContactSuccessPage'));

const PageContent = ({ location }: Partial<RouteComponentProps>) => {
  const theme = useTheme();
  const classes = useStyles();
  const allTabs = ['/', '/stocks'];

  const tab = () => {
    if (!location || !allTabs.includes(location.pathname)) {
      return false;
    }
    return location.pathname;
  };

  return (
    <>
      <GlobalSnackbar />
      <div className={classes.header}>
        <Link to="/">
          <img className={classes.logo} alt="Die Besten Aktien" src={require('application/assets/logo.png')} />
        </Link>
      </div>
      <div className={classes.divider} />
      <div className={classes.headerWrapper}>
        <div className={classes.header}>
          <AppBar position="static" elevation={0}>
            <Tabs value={tab()}>
              <Tab label="Home" value={allTabs[0]} component={Link} to={allTabs[0]} />
              <Tab label="Aktien" value={allTabs[1]} component={Link} to={allTabs[1]} />
            </Tabs>
          </AppBar>
        </div>
      </div>
      <Suspense fallback={<Preloader white />}>
        <Switch>
          <Route path={allTabs[0]} exact component={HomePage} />
          <Route path={allTabs[1]} exact component={StocksPage} />
          <Route path="/imprint" exact component={ImprintPage} />
          <Route path="/faq" exact component={FaqPage} />
          <Route path="/contact" exact component={ContactPage} />
          <Route path="/contact/success" exact component={ContactSuccessPage} />
          <Route path="/instagram" exact component={InstagramPage} />
          <Route path="/quote/:symbol" exact component={QuotePage} />
          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
      <CookieConsent
        // debug={true}
        // enableDeclineButton
        onAccept={() => {
          initGA();
        }}
        // onDecline={() => {
        //   alert('consent not given');
        // }}
        location="bottom"
        buttonText="Akzeptieren!"
        declineButtonText="Ablehnen"
        cookieName="consentCookie"
        style={{ background: theme.palette.primary.dark }}
        buttonStyle={{ background: theme.palette.error.main, color: common.white, fontSize: '16px' }}
        declineButtonStyle={{ background: theme.palette.error.main, color: common.white, fontSize: '10px' }}
      >
        Unsere Webseite nutzt Cookies, um Ihnen ein besseres Nutzererlebnis zu bieten. Durch die weitere Nutzung der
        Webseite stimmen Sie unseren AGB zu.{' '}
        <span style={{ fontSize: '10px' }}>Sie können Ihre Einwilligung jederzeit widerrufen.</span>
      </CookieConsent>
    </>
  );
};

export default PageContent;
