import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { common } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    width: '100%',
    background: theme.palette.primary.main,
  },
  header: {
    ...theme.appContainerStyles,
    paddingLeft: 0,
    paddingRight: 0,
  },
  container: {
    ...theme.appContainerStyles,
  },
  divider: {
    height: 1,
    backgroundColor: theme.palette.primary.dark,
  },
  logo: {
    width: 200,
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
  },
  acceptButton: {
    background: theme.palette.secondary.light,
    color: common.black,
    fontSize: '13px',
  },
  declineButton: {
    background: theme.palette.secondary.light,
    color: common.black,
    fontSize: '13px',
  },
}));
