import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { muiTheme } from './theme/muiTheme';
import PageContent from './application/containers/PageContent/PageContent';
import { initGA } from './helpers/analytics';
import { StoreProvider } from './store/StoreProvider';
import { HelmetProvider } from 'react-helmet-async';

initGA();

function App() {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <StoreProvider>
          <MuiThemeProvider theme={muiTheme}>
            <CssBaseline />
            <Route path="/" render={(props) => <PageContent {...props} />} />
          </MuiThemeProvider>
        </StoreProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
