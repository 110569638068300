const TIMEOUT_ERROR = 'TimeoutError';

export class TimeoutError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = TIMEOUT_ERROR;
    if (message) {
      this.message = message || `Timeout error while calling endpoint`;
    }
    this.stack = new Error().stack;
  }
}

export function isTimeoutError(e: Error): boolean {
  return e.name === TIMEOUT_ERROR;
}
