import { Cookies } from 'react-cookie-consent';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { __DEV__ } from './platform';

export const initGA = () => {
  const consentOk = Cookies.get('consentCookie');
  if (consentOk && !__DEV__) {
    ReactGA.initialize('UA-179689630-1'/*, { debug: !__DEV__ }*/); // put your tracking id here
  }
};

export default function useAnalyticsPageView() {
  const location = useLocation();
  const consentOk = Cookies.get('consentCookie');
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

  useEffect(() => {
    if (consentOk && !isDev) {
      const currentPath = location.pathname + location.search;
      ReactGA.pageview(currentPath);
    }
  }, [location, consentOk, isDev]);
}

export const removeAllCookies = () => {
  Object.keys(Cookies.get()).forEach(function (cookieName) {
    Cookies.remove(cookieName);
    alert('Alle Cookies gelöscht!');
  });
};

// how remove all cookies
// https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#how-to-remove-all-cookies
