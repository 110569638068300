import { ApiResponse, ApisauceInstance, create, HEADERS, PROBLEM_CODE } from 'apisauce';
import { AxiosRequestConfig } from 'axios';
import { HOST_URL } from 'config/config';
import { __DEV__ } from 'helpers/platform';

export const api: ApisauceInstance = create({
  baseURL: HOST_URL,
});

export type Method = 'GET' | 'POST' | 'DELETE' | 'PATCH';

export interface ErrorWithStatus extends Error {
  status?: number;
}

export interface ErrorItem {
  code: string;
  detail: string;
  status: number;
}

export interface ResponseError {
  problem: PROBLEM_CODE | null;
  data?: {
    errors: ErrorItem[];
  };
}

export interface CallApiOptions {
  url: string;
  body?: {
    [key: string]: any;
  };
  axiosConfig?: AxiosRequestConfig;
  method?: Method;
  showError?: boolean; // if showError is undefined, the error is displayed as toast
  timeout?: number; // if is undefined, the default timeout is used
}

interface ApiOptions {
  apiUrl: string;
  headers?: HEADERS;
}

export class Api {
  constructor({ apiUrl, headers }: ApiOptions) {
    api.setBaseURL(apiUrl);
    api.setHeaders(headers || {});
  }

  private apisauceErrorMessage = (problem: string) => {
    switch (problem) {
      case 'SERVER_ERROR':
        return 'ErrorServer';
      case 'NETWORK_ERROR':
        return 'ErrorNetwork';
      case 'CONNECTION_ERROR':
        return 'ErrorConnection';
      case 'TIMEOUT_ERROR':
        return 'ErrorTimeout';
      case 'CLIENT_ERROR':
        return 'ErrorClient';
      default:
        return problem;
    }
  };

  private getParsedAPIError = (response: ResponseError) => {
    let errorMessage: string;
    if (!response || !response.problem) {
      errorMessage = 'ErrorServer';
    } else {
      errorMessage = this.apisauceErrorMessage(response.problem);
    }

    if (response.data && response.data.errors && Array.isArray(response.data.errors) && response.data.errors.length) {
      errorMessage = response.data.errors.reduce(
        (accumulator: string, currentValue: ErrorItem) => accumulator + ' ' + currentValue.detail,
        '',
      );
    }

    return errorMessage;
  };

  public setHeaders = (headers: HEADERS) => {
    api.setHeaders(headers);
  };

  public setBaseUrl = (baseUrl: string) => {
    api.setBaseURL(baseUrl);
  };

  public getBaseUrl = () => {
    return api.getBaseURL();
  };

  private logRequest = (body: any, { data, errors }: { data?: any; errors?: Error[] }) => {
    if (console.groupCollapsed) {
      console.log('Body', body);
      if (data) {
        console.log('Result', data);
      }

      if (errors) {
        console.groupCollapsed('ERRORS');
        errors.forEach((error) => {
          console.log(error.message);
        });
        console.groupEnd();
      }
      console.groupEnd();
    }
  };

  private response = async (
    method: Method,
    url: string,
    body?: {
      [key: string]: any;
    },
    axiosConfig?: AxiosRequestConfig,
  ) => {
    switch (method) {
      case 'GET':
        return await api.get(url, body, axiosConfig);
      case 'DELETE':
        return await api.delete(url, body, axiosConfig);
      case 'PATCH':
        return await api.patch(url, body, axiosConfig);
      default:
        return await api.post(url, body, axiosConfig);
    }
  };

  public call = async ({ url, body, axiosConfig, method = 'POST' }: CallApiOptions) => {
    if (!api.getBaseURL()) {
      console.warn('No Api Url provided');
      return {};
    }

    try {
      const response: ApiResponse<any> = await this.response(method, url, body, axiosConfig);

      if (response.ok) {
        if (response.data) {
          if (__DEV__) {
            this.logRequest(body, { data: response.data });
          }
          return { data: response.data };
        } else {
          return { data: response };
        }
      } else {
        // we attach error message AND status here
        const errorMessage = this.getParsedAPIError(response);
        const error = new Error(errorMessage) as ErrorWithStatus;
        error.status = response.status;
        throw error;
      }
    } catch (e) {
      if (__DEV__) {
        this.logRequest(body, { errors: [e] });
      }
      return { error: e };
    }
  };
}
