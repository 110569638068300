import * as React from 'react';
import { Provider } from 'react-redux';
import { store } from './store';

export const StoreProvider: React.FC = (props) => {
  return (
    <Provider store={store}>
      {/*<PersistGate loading={null} persistor={persistor}>*/}
      {props.children}
      {/*</PersistGate>*/}
    </Provider>
  );
};
