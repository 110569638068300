import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

export const useStyles = makeStyles((theme: Theme) => ({
  snackbar: {
    minWidth: 320,
  },
  root: {
    width: '100%',
    ...theme.typography.h6,
  },
  icon: {
    fontSize: 22,
  },
}));
