import { Pairs } from 'types/types';
import { amber, brown, cyan, lightBlue, lime, orange, pink, teal } from '@material-ui/core/colors';

export const HOST_URL = 'https://klabusta.com/StockIndicators/';

export const INDICATORS: Pairs = {
  rank: 'sorted symbols by rank of weighted 200 MA Factor, TSI, MACD',
  tsi: 'sorted symbols by tsi',
  macd: 'sorted symbols by MACD',
  ma200: 'sorted symbols by quote/200 MA',
  ema: 'sorted symbols by 26 EMA',
  macd9: 'sorted symbols by last 9 MACD average',
  // obv: 'sorted symbols by OBV',
};

export const INDICATOR_LABELS: Pairs = {
  rank: 'Die Besten Aktien Rank',
  tsi: 'Trend Signale Indikator',
  macd: 'Moving Average Convergence/Divergence',
  ma200: '200 Day Moving Average',
  ema: '26 Day Exponential Moving Average',
  macd9: '9 Day Moving Average Convergence/Divergence',
  // obv: 'On Balance Volume Indikator',
};

export const TOPS_COUNT = 6;
export const FLOPS_COUNT = 4;

export interface ColorParis {
  [key: string]: Pairs;
}

export const COLOR: ColorParis = {
  orange: orange,
  lime: lime,
  cyan: cyan,
  teal: teal,
  pink: pink,
  lightBlue: lightBlue,
  amber: amber,
  brown: brown,
};

export const COLOR_LABELS: Pairs = {
  orange: 'orange',
  lime: 'lime',
  cyan: 'cyan',
  teal: 'teal',
  pink: 'pink,',
  lightBlue: 'lightBlue',
  amber: 'amber',
  brown: 'brown',
};

// import { currentLanguageCode } from '~/i18n';
//
// export const EXPIRES_IN_SECONDS = 30 * 24 * 60 * 60; // 30 days in seconds
//
// /**
//  * URLs
//  */
// export const GLUE_URL_PREPROD = 'https://glue-reward-shop.preprod.continental.aws-arvato.com';
// export const HOST_URL_PREPROD = 'http://react.preprod.continental.aws-arvato.com';
//
// export const GLUE_URL_PROD = 'https://glue-continental-demo.friendsofc.com';
// export const HOST_URL_PROD = 'https://morecontinental.arvato-360.com';
//
// export const YVES_URL_PREPROD = 'https://www-reward-shop.preprod.continental.aws-arvato.com';
// export const YVES_URL_PROD = 'https://continental-demo.friendsofc.com';
//
// export const backendTypes = [{ value: GLUE_URL_PROD, label: 'Prod' }, { value: GLUE_URL_PREPROD, label: 'Preprod' }];
//
// export let hostUrl = HOST_URL_PROD;
//
// export let yvesUrl = YVES_URL_PROD;
//
// export let timeZone = 'Europe/Berlin';
//
// export const MAX_NEWS_ITEMS = 10;
//
// export function setTimeZone(tz?: string) {
//   if (tz && tz.length > 0) {
//     timeZone = tz;
//   }
// }
//
// export function getTimeZone() {
//   return timeZone;
// }
//
// export function setHostUrl(url: string) {
//   if (url && url.length > 0) {
//     hostUrl = url;
//   }
// }
//
// export function getHostUrl() {
//   return hostUrl;
// }
//
// export function setYvesUrl(url: string) {
//   if (url && url.length > 0) {
//     yvesUrl = url;
//   }
// }
//
// export function getYvestUrl() {
//   return yvesUrl;
// }
//
// export function getLocalizedYvestUrl() {
//   return `${getYvestUrl()}/${currentLanguageCode().toLowerCase()}`;
// }
//
// export function getShopUrl() {
//   return `${getHostUrl()}/category/new`;
// }
//
// export function getOrderDetailsUrl() {
//   return `${getHostUrl()}/customer/order/details/`;
// }
//
// export function getProductUrl() {
//   return `${getHostUrl()}/product/`;
// }
//
// export function getResetPasswordUrl() {
//   return `${getHostUrl()}/password/forgotten`;
// }
//
// export const TRANSACTIONS_LIMIT = 10;
